<template>
    <div class="modal-reject-refund">
        <div class="head">
            <div class="title" v-html="$translate('MODAL_REJECT_REFUND_TITLE')" />
            <div class="desc" v-html="$translate('MODAL_REJECT_REFUND_DESC')" />
        </div>
        <div class="hr" />
        <div class="section">
            <div class="title" v-html="$translate('MODAL_REJECT_REFUND_DESCRIPTION')" />
            <TextareaWithAutoTexts
                v-model="payload.description"
                :placeholder="'PLACEHOLDER_FILL_CONTENT'"
                :hideAutotexts="true"
            />
        </div>
        <div class="section">
            <div class="title" v-html="$translate('MODAL_REJECT_REFUND_PHOTOS')" />
            <div class="photos flex-row">
                <div
                    @click="onClickPhoto(idx)"
                    class="photo flex-wrap"
                    :key="idx"
                    v-for="(photo, idx) in payload.photos"
                >
                    <i class="zmdi zmdi-camera" />
                    <img v-if="photo.url" :src="photo.url" />
                    <input
                        ref="imageUploader"
                        type="file"
                        class="image display-none"
                        @change="onChangeImage($event, photo)"
                        accept="image/*"
                    />
                </div>
            </div>
        </div>
        <div class="hr" />
        <div class="buttons bottom">
            <div class="flex-row">
                <button @click="$emit('close', 0)" class="btn-default" v-html="$translate('CANCEL')" />
                <button
                    ref="confirm"
                    @click="onConfirm"
                    :class="disableConfirm ? 'disabled' : 'btn-primary'"
                    v-html="$translate('SUBMIT')"
                />
            </div>
        </div>
    </div>
</template>
<script>
import refundService from '@/services/refund'

export default {
    name: 'ModalRejectRefund',
    props: ['options'],
    data: () => ({
        payload: {
            description: null,
            photos: [{ url: null }, { url: null }, { url: null }],
        },
    }),
    computed: {
        disableConfirm() {
            return this.payload.photos.filter(photo => photo.url).length === 0 || !this.payload.description
        },
    },
    methods: {
        onChangeImage(event, photo) {
            const reader = new FileReader()
            reader.addEventListener('load', () => {
                photo.url = reader.result
            })

            const file = event.target.files[0]
            if (file.size > 1000 * 1000 * 3) {
                this.$toast.error('IMAGE_TOO_BIG')
                return
            }

            photo.file = file
            reader.readAsDataURL(file)
        },
        onClickPhoto(idx) {
            this.$refs.imageUploader[idx].click()
        },
        async onConfirm() {
            if (this.disableConfirm) {
                this.$toast.error('ERROR_REJECT_REFUND_INVALID_PAYLOAD')
                return
            }

            const preparedPayload = () => {
                const data = new FormData()
                data.append('description', this.payload.description)
                this.payload.photos
                    .filter(photo => photo.file)
                    .forEach((photo, idx) => {
                        data.append(`photo${idx + 1}`, photo.file)
                    })
                return data
            }

            this.$loading(true)
            try {
                const resp = await refundService.reject(this.options.refund.id, preparedPayload())
                this.$toast.success(resp.msg)
                this.$emit('close', 1)
            } catch (e) {
                this.$toast.error(e.data)
            }
            this.$loading(false)
        },
    },
}
</script>
